import { apiAddProgramShowInfo, apiQueryAllProgramInfoList, apiQueryAllProgramShowInfoList } from "@/apis/api-banpai"
import { Button, DatePicker, Input, InputNumber, Modal, Row, Select, Space, Table, TimePicker, message,Typography, Checkbox, Radio } from "antd"
import { useEffect, useState } from "react"
import { dayOfWeekString, durationFormat } from "./duration-format-utils"
import dayjs from "dayjs"
import AreaPicker from "@/components/area-picker"
import { apiGetGradeClass, apiGetSchoolGradeList, apiGetSchoolInfo } from "@/apis/api-banpai";

const {RangePicker} = DatePicker
const { Text } = Typography

export default function ProgramList(){

    const [pageIndex,setPageIndex] = useState(0)
    const [programList,setProgramList] = useState([])
    const [appList,setAppList] = useState([])
    const [schoolList,setSchoolList] = useState([])
    const [gradeList,setGradeList] = useState([])
    const [classList,setClassList] = useState([])
    const [isShowEditorModal,setIsShowEditorModal] = useState(false)
    const [selectedProgram,setSelectedProgram] = useState({})
    
    const fetchAppList = async () =>{
        const res = await apiQueryAllProgramInfoList({pageIndex:0,pageCount:1000})
        if(res.result === 0){
            setAppList(res.data)
        }
    }

    const fetchProgramList = async () =>{
        const res = await apiQueryAllProgramShowInfoList({pageIndex,pageCount:10})
        if(res.result !== 0){
            message.error('获取节目列表失败,' + res.msg)
            return
        }
        res.data.forEach((item)=>{
            item.startTimeNumber = dayjs(item.startTime).unix()
            item.endTimeNumber = dayjs(item.endTime).unix()
        })
        setProgramList(res.data)
    }

    const fetchSchool = async () => {
        // console.log('fetchSchool1',areaValue)
        const province = selectedProgram?.provinceId || 0
        const city = selectedProgram?.cityId || 0
        const county = selectedProgram?.countyId || 0
        console.log("fetchSchool2",province,city,county)
        const res = await apiGetSchoolInfo({
          province,
          city,
          county,
          name:'',
          phaseType:0,
          pageIndex:1,
          pageSize:1000
        })
        if(res.result === 0){
          // console.log('fetchSchool3',res.data)
          setSchoolList(res.data)
        }else{
          message.error('获取学校列表错误，' + res.msg)
        }
    }
    const fetchGrade = async () =>{
        const res = await apiGetSchoolGradeList({
            schoolId:selectedProgram?.schoolId || 0
        })
        if(res.result === 0){
            console.log(res.data)
            setGradeList(res.data)
        }else{
            message.error('获取年级列表错误，' + res.msg)
        }
    }

    const fetchClass = async () => {
        const res = await apiGetGradeClass({
            schoolId:selectedProgram?.schoolId || 0,
            gradeId:selectedProgram?.gradeId || 0,
            mode:0,
            pageIndex:1,
            pageSize:1000
        })
        if(res.result === 0){
            console.log("fetch class",res.data)
            setClassList(res.data)
        }else{
            message.error('获取班级列表错误，'+ res.msg)
        }
    }

    useEffect(()=>{
        fetchAppList()
    },[])

    useEffect(()=>{
        fetchProgramList()
    },[pageIndex])

    useEffect(()=>{
        fetchSchool()
    },[selectedProgram?.provinceId,selectedProgram?.cityId,selectedProgram?.countyId])

    useEffect(()=>{
        fetchGrade()
    },[selectedProgram?.schoolId])

    useEffect(()=>{
        fetchClass()
    },[selectedProgram?.classId])

    const getLocationInfo = ({provinceId,cityId,countyId,schoolId,gradeId,classId})=>{
        return provinceId + "-" + cityId + "-" + countyId + "-" + schoolId + "-" + gradeId + "-" + classId

    }

    const columns = [
{        key:"id",title:'id',dataIndex:'id'},
        {title:'program',key:'program',render:(_,info)=>(
            <>
                {appList?.find((item)=>{return item.id === info.programId})?.name}
            </>
        )},
        {title:'location',key:'location',render:(_,info)=>(
            <>
            {getLocationInfo(info)}
            </>
        )},
        {title:'datetime',key:'datetime',render:(_,programInfo)=>(
            <>
                {programInfo.startTime} - {programInfo.endTime}
            </>
        )},
        {title:'plan',key:'plan',render:(_,programInfo)=>(
            <Space direction="vertical">
                <Text> {programInfo.isCycle === 1 ? '循环' : '一次'}</Text>
                <Text>{dayOfWeekString(programInfo.dayOfWeek)}</Text>
                <Text>{durationFormat(programInfo.cycleStartTime)} - {durationFormat(programInfo.cycleEndTime)}</Text>
                
            </Space>
        )},
        {title:'priority',key:'priority',dataIndex:'priority'},
        {
            title:'action',key:'action',render:(_,programInfo)=>(
                <Space>
                    <a onClick={(e)=>{
                        setSelectedProgram(programInfo)
                        setIsShowEditorModal(true)
                        console.log(programInfo.cycleStartTime)
                        console.log(dayjs(programInfo.cycleStartTime))
                    }}>编辑</a>
                    <a >删除</a>
                </Space>
            )
        }
    ]


    const handleEditorModelOkClick = async () =>{
        const res = await apiAddProgramShowInfo(selectedProgram)
        if(res.result !==0){
            message.error('编辑节目展示失败,' + res.msg)
            return
        }
        setIsShowEditorModal(false)
        fetchProgramList()
    }


    return(<>

        <Space>
            <Button type="primary" onClick={(e)=>{
                setIsShowEditorModal(true)
                setSelectedProgram({
                    isCycle:0
                })
            }}>新增</Button>

            <Button type="primary" onClick={(e)=>{
                fetchProgramList()
            }}>刷新</Button>
        </Space>





        
        <Table dataSource={programList} columns={columns}>

        </Table>

        <Modal
            title='编辑'
            open={isShowEditorModal}
            onOk={handleEditorModelOkClick}
            okText="确定"
            onCancel={(e)=>{setIsShowEditorModal(false)}}
        >
            <Space direction="vertical">
                <Space>
                    <Text>应用名称 : </Text>
                <Select value={selectedProgram?.programId} style={{width:100}}
                    options={appList?.map((item)=>({
                        label:item.name,
                        value:item.id
                    }))}
                    onChange={(v)=>{
                        setSelectedProgram({
                            ...selectedProgram,
                            programId:v
                        })
                    }}
                >

                </Select>
                </Space>
               
               <Space>
                    <AreaPicker 
                        style={{width:300}}
                        value={[selectedProgram?.provinceId || 0,selectedProgram?.cityId || 0, selectedProgram?.countyId || 0]}
                        onChange={(v,options)=>{
                            setSelectedProgram({
                                ...selectedProgram,
                                provinceId:v[0] || 0,
                                cityId:v[1] || 0,
                                countyId:v[2] || 0,
                                schoolId:0,
                                gradeId:0,
                                classId:0
                        })      
                    }}
                    />
               </Space>
               <Space>
                   <Select 
                        style={{minWidth:100}}
                        value={selectedProgram?.schoolId || 0} 
                        onSelect={(id)=>{setSelectedProgram({
                            ...selectedProgram,
                            schoolId:id
                        })}}
                        options={schoolList.map((item)=>(
                            {
                                label:item.name,
                                value:item.id
                            }
                        ))}
                   />
                    <Select 
                        style={{minWidth:100}}
                        value={selectedProgram?.gradeId || 0} onSelect={(id)=>{setSelectedProgram({
                    ...selectedProgram,
                    gradeId:id
                   })}}
                   options={gradeList.map((item)=>(
                    {
                      label:item.gradeName,
                      value:item.gradeId
                    }
                  ))}
                   
                    />
                    <Select 
                        style={{minWidth:100}}
                        value={selectedProgram?.classId || 0} onSelect={(id)=>{setSelectedProgram({
                    ...selectedProgram,
                    classId:id
                   })}}
                   
                   options={classList.map((item)=>(
                    {
                      label:item.className,
                      value:item.classId
                    }
                  ))}
                   />
               </Space>
        
<p>productType</p>
                <InputNumber value={selectedProgram?.productType|| 0} onChange={(v)=>{
                    setSelectedProgram({
                        ...selectedProgram,
                        productType:v
                    })
                }}></InputNumber>
<p>startTime</p>
               

                <RangePicker
                    value={[dayjs((selectedProgram?.startTimeNumber || (Date.parse(new Date())/1000)) * 1000),dayjs((selectedProgram?.endTimeNumber || (Date.parse(new Date())/1000))  * 1000)]}
                    onCalendarChange={(dates)=>{
                        console.log(dates)
                        setSelectedProgram({
                            ...selectedProgram,
                            startTimeNumber:dates[0].startOf('d').unix(),
                            endTimeNumber:dates[1]?.endOf('d').unix() || 0
                        })
                }}/>

{/* <p>endTime</p>
                <InputNumber value={selectedProgram?.endTime} onChange={(e)=>{
                    setSelectedProgram({
                        ...selectedProgram,
                        endTime:e.target.value
                    })
                }}></InputNumber> */}
<p>isCycle</p>
                <Radio.Group 
                    value={selectedProgram?.isCycle || 0} 
                    onChange={(e)=>{
                        setSelectedProgram({
                            ...selectedProgram,
                            isCycle:e.target.value
                        })
                    }}
                >
                    <Radio value={0}>循环</Radio>
                    <Radio value={1}>一次性</Radio>
                </Radio.Group>

<p>dayOfWeek {selectedProgram?.dayOfWeek}</p>
                <Space>
                    <Checkbox checked={(selectedProgram?.dayOfWeek & 1) === 1} onChange={(e)=>{
                        if(e.target.checked){
                            const result = selectedProgram.dayOfWeek | 1
                            setSelectedProgram({
                                ...selectedProgram,
                                dayOfWeek:result
                            })
                        }else{
                            const result = selectedProgram.dayOfWeek ^ 1
                            setSelectedProgram({
                                ...selectedProgram,
                                dayOfWeek:result
                            })
                        }
                    }}>周一</Checkbox>
                    <Checkbox checked={(selectedProgram?.dayOfWeek & 2) === 2 } onChange={(e)=>{
                        if(e.target.checked){
                            const result = selectedProgram.dayOfWeek | 2
                            setSelectedProgram({
                                ...selectedProgram,
                                dayOfWeek:result
                            })
                        }else{
                            const result = selectedProgram.dayOfWeek ^ 2
                            setSelectedProgram({
                                ...selectedProgram,
                                dayOfWeek:result
                            })
                        }
                    }}>周二</Checkbox>
                    <Checkbox checked={(selectedProgram?.dayOfWeek & 4) === 4} onChange={(e)=>{
                        if(e.target.checked){
                            const result = selectedProgram.dayOfWeek | 4
                            setSelectedProgram({
                                ...selectedProgram,
                                dayOfWeek:result
                            })
                        }else{
                            const result = selectedProgram.dayOfWeek ^ 4
                            setSelectedProgram({
                                ...selectedProgram,
                                dayOfWeek:result
                            })
                        }
                    }}>周三</Checkbox>
                    <Checkbox checked={(selectedProgram?.dayOfWeek & 8) === 8} onChange={(e)=>{
                        if(e.target.checked){
                            const result = selectedProgram.dayOfWeek | 8
                            setSelectedProgram({
                                ...selectedProgram,
                                dayOfWeek:result
                            })
                        }else{
                            const result = selectedProgram.dayOfWeek ^ 8
                            setSelectedProgram({
                                ...selectedProgram,
                                dayOfWeek:result
                            })
                        }
                    }}>周四</Checkbox>
                    <Checkbox checked={(selectedProgram?.dayOfWeek & 16) === 16} onChange={(e)=>{
                        if(e.target.checked){
                            const result = selectedProgram.dayOfWeek | 16
                            setSelectedProgram({
                                ...selectedProgram,
                                dayOfWeek:result
                            })
                        }else{
                            const result = selectedProgram.dayOfWeek ^ 16
                            setSelectedProgram({
                                ...selectedProgram,
                                dayOfWeek:result
                            })
                        }
                    }}>周五</Checkbox>
                    <Checkbox checked={(selectedProgram?.dayOfWeek & 32) === 32} onChange={(e)=>{
                        if(e.target.checked){
                            const result = selectedProgram.dayOfWeek | 32
                            setSelectedProgram({
                                ...selectedProgram,
                                dayOfWeek:result
                            })
                        }else{
                            const result = selectedProgram.dayOfWeek ^ 32
                            setSelectedProgram({
                                ...selectedProgram,
                                dayOfWeek:result
                            })
                        }
                    }}>周六</Checkbox>
                    <Checkbox checked={(selectedProgram?.dayOfWeek & 64) === 64} onChange={(e)=>{
                        if(e.target.checked){
                            const result = selectedProgram.dayOfWeek | 64
                            setSelectedProgram({
                                ...selectedProgram,
                                dayOfWeek:result
                            })
                        }else{
                            const result = selectedProgram.dayOfWeek ^ 64
                            setSelectedProgram({
                                ...selectedProgram,
                                dayOfWeek:result
                            })
                        }
                    }}>周日</Checkbox>
                </Space>

                {/* <InputNumber value={|| 0} onChange={(v)=>{
                    setSelectedProgram({
                        ...selectedProgram,
                        dayOfWeek:v
                    })
                }}></InputNumber> */}
<p>cycleStartTime</p>
                {/* <InputNumber value={selectedProgram?.cycleStartTime|| 0} onChange={(v)=>{
                    setSelectedProgram({
                        ...selectedProgram,
                        cycleStartTime:v
                    })
                }}></InputNumber> */}

                <TimePicker.RangePicker 
                
                value={[dayjs((selectedProgram?.cycleStartTime||0) * 1000 - (8*3600*1000)),dayjs((selectedProgram?.cycleEndTime || 0) * 1000- (8*3600*1000))]}
                format="HH:mm" onChange={(v)=>{
                    const start = v[0].hour() * 3600 + v[0].minute() * 60
                    const end = v[1].hour() * 3600 + v[1].minute() * 60
                    console.log(v)
                    console.log(start)
                    console.log(end)
                    setSelectedProgram({
                        ...selectedProgram,
                        cycleStartTime:start,
                        cycleEndTime:end
                    })
                }}/>

{/* <p>cycleEndTime</p>
                <InputNumber value={selectedProgram?.cycleEndTime|| 0} onChange={(v)=>{
                    setSelectedProgram({
                        ...selectedProgram,
                        cycleEndTime:v
                    })
                }}></InputNumber> */}
<p>优先级：</p>

                <Select 
                    style={{minWidth:200}}
                    value={selectedProgram?.priority || 1}
                    onSelect={(id)=>{
                        setSelectedProgram({
                            ...selectedProgram,
                            priority:id
                        })
                    }}
                    options={[
                        {value:1,label:'1(最低)'},
                        {value:2,label:'2'},
                        {value:3,label:'3'},
                        {value:4,label:'4'},
                        {value:5,label:'5(最高)'}
                    ]}
                />

            </Space>


        </Modal>

    </>)

}