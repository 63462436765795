import config from '../shared/config'
import { history } from '@/shared/history'

async function helper(method, url, data = {}, options = {}) {
  const token = localStorage.getItem('token')

  let newUrl = null
  if (url.startsWith('http://') || url.startsWith('https://')) {
    newUrl = url
  } else {
    newUrl = config.banpaiSvr.domain + url
  }
  let json = null
  try {
    const withoutToken = options.withoutToken
    const newData = withoutToken ? data : { ...data, token }
    if (method === 'GET') {
      const arr = []
      Object.keys(newData).forEach((k) => arr.push(`${k}=${newData[k]}`))
      if (newUrl.includes('?')) {
        newUrl += '?' + arr.join('&')
      } else {
        newUrl += '&' + arr.join('&')
      }
    }

    const newOptions = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      ...options,
    }

    if (method === 'POST') {
      newOptions.body = JSON.stringify(newData)
    }

    const response = await fetch(newUrl, newOptions)
    try {
      json = await response.json()
      if (json.result === 13003) {
        // token 过期
        history.navigate('/login', {
          state: { from: history.location },
          replace: true,
        })
      }
    } catch (err) {
      let bodyText = await response.text()
      json = {
        result: -1,
        msg: bodyText,
      }
    }
  } catch (err) {
    console.error(err.message)
    json = null
  }
  return json
}

export async function post(url, data = {}, options = {}) {
  const response = await helper('POST', url, data, options)
  return response
}

export async function get(url, data = {}, options = {}) {
  const response = await helper('GET', url, data, options)
  return response
}
