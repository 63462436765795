import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiQueryAllPriviledgeList } from '@/apis/api-sys-manager'
import { buildMenuTree } from '@/shared/tree-utils'

const initialState = []

export const buildTotalMenu = createAsyncThunk(
  'menuSlice/buildTotalMenu',
  async () => {
    const resList = await apiQueryAllPriviledgeList()
    if (resList.result !== 0) {
      return
    }

    const list = resList.list.map((v) => ({
      ...v,
      key: v.id,
      label: v.name,
    }))

    const tree = buildMenuTree(list)
    return tree
  },
)

export const totalMenuSlice = createSlice({
  name: 'totalMenu',
  initialState,
  reducers: {
    setTotalMenuInfo: (_state, action) => {
      return action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(buildTotalMenu.fulfilled, (state, action) => {
      return action.payload
    })
  },
})

export const { setTotalMenuInfo } = totalMenuSlice.actions
export default totalMenuSlice.reducer
