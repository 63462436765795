import { apiAddProviderInfo, apiQueryAllProviderInfoList } from "@/apis/api-banpai"
import { Button, Input, Modal, Row, Space, Table, message } from "antd"
import { useEffect, useState } from "react"


export default function ProviderList(){

    const [providerList,setProviderList] = useState([])
    const [pageIndex,setPageIndex] = useState(0)
    const [editProvider,setEditProvider] = useState({})
    const [isEditProvider,setIsEditProvider] = useState(false)
    const fetchProviderList = async ()=>{
        const res = await apiQueryAllProviderInfoList({pageIndex,pageCount:10})
        if(res.result !== 0){
            message.error('获取供应商列表失败，' + res.msg)
            return
        }
        setProviderList(res.data)
    }

    const columns = [
        {title:'id',key:'id',dataIndex:'id'},
        {title:'name',key:'name',dataIndex:'name'},
        {title:'desc',key:'desc',dataIndex:'desc'},
        {title:'address',key:'address',dataIndex:'address'},
        {title:'contact',key:'contact',dataIndex:'contact'},
        {title:'createTime',key:'createTime',dataIndex:'createTime'},
        {title:'creator',key:'creator',dataIndex:'creator'},
        {
            title:'action',key:'action',render:(_,info)=>(
                <>
                    <Space>
                        <a href onClick={(e)=>{
                            setEditProvider(info)
                            setIsEditProvider(true)
                        }}>编辑</a>
                        <a href>删除</a>
                    </Space>
                </>
            )
        }
    ]

    const handleClickAddProvider = ()=>{
        setIsEditProvider(true)
        setEditProvider({id:0})
    }

    const handleEditorModelOkClick = async ()=>{
        // if(editProvider.id === 0){
            const res = await apiAddProviderInfo(editProvider)
            if(res.result !== 0){
                message.error('添加失败,' + res.msg);
                return
            }
            fetchProviderList()
            setIsEditProvider(false)
        // }else{

        // }
    }

    useEffect(()=>{
        fetchProviderList()
    },[pageIndex])

    return (
        <>
        <Row>
            <Button type="primary" onClick={handleClickAddProvider}>添加供应商</Button>
        </Row>
            <Table columns={columns} dataSource={providerList}></Table>
        <Modal
            title='编辑供应商'
            open={isEditProvider}
            okText='确定'
            onCancel={(e)=>{setIsEditProvider(false)}}
            onOk={handleEditorModelOkClick}
        >
            <div>
                <p>name</p>
                <Input 
                    value={editProvider?.name} 
                    onChange={(e)=>{
                        setEditProvider({
                            ...editProvider,
                            name:e.target.value
                        })
                        }
                    }
                />
                <p>desc</p>
                <Input
                    value={editProvider?.desc} 
                    onChange={(e)=>{
                        setEditProvider({
                            ...editProvider,
                            desc:e.target.value
                        })
                    }}
                />
                <p>address</p>
                <Input
                    value={editProvider?.address} 
                    onChange={(e)=>{
                        setEditProvider({
                            ...editProvider,
                            address:e.target.value
                        })
                    }}
                />
                <p>contact:</p>
                <Input
                    value={editProvider?.contact} 
                    onChange={(e)=>{
                        setEditProvider({
                            ...editProvider,
                            contact:e.target.value
                        })
                    }}
                />
            </div>


        </Modal>
 
        </>
    )
}