import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  common: {
    resurl: 'https://aohuaresource.oss-cn-beijing.aliyuncs.com',
    weburl: '',
  },
}

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state, action) => {
      return action.payload
    },
  },
})

export const { setConfig } = configSlice.actions
export default configSlice.reducer
