import { post } from './request'
import { APP_ID } from '@/shared/constants'

const DOMAIN = 'https://login.aohuacloud.com'

export async function apiGetAppConfigInfo() {
  return await post(`${DOMAIN}/service/login/getAppConfigInfo`, {
    appId: APP_ID,
    moduleId: 0,
  })
}

export async function apiLoginByPhone({ phoneNumber, code } = {}) {
  return await post(
    `${DOMAIN}/service/login/LoginByPhone`,
    {
      phoneNumber,
      code,
    },
    { withoutToken: true },
  )
}

export async function apiLoginByToken({ token } = {}) {
  return await post(
    `${DOMAIN}/service/login/LoginByToken`,
    {
      token,
    },
    { withoutToken: true },
  )
}

export async function apiSendPhoneCode({ appId, subId, phoneNumber } = {}) {
  return await post(
    `${DOMAIN}/service/login/sendPhoneCode`,
    {
      appId,
      subId,
      phoneNumber,
    },
    { withoutToken: true },
  )
}
