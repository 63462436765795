import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiQueryAllPriviledgeList } from '@/apis/api-sys-manager'
import { getParents, buildMenuTree } from '@/shared/tree-utils'
import _ from 'lodash'

const initialState = []

export const buildMenu = createAsyncThunk(
  'menuSlice/buildMenu',
  async (userPriviledge) => {
    const resList = await apiQueryAllPriviledgeList()
    if (resList.result !== 0) {
      return
    }

    let list
    if (
      userPriviledge.priviledge.length === 1 &&
      userPriviledge.priviledge[0] === 0
    ) {
      list = resList.list.map((v) => ({
        ...v,
        key: v.id,
        label: v.name,
      }))
    } else {
      list = []
      for (const o of userPriviledge.priviledge) {
        const { parents } = getParents(o, resList.list)
        list.push(...parents)
      }

      list = _.uniqBy(list, (v) => v.id).map((v) => ({
        ...v,
        key: v.id,
        label: v.name,
      }))
    }

    const tree = buildMenuTree(list)
    return tree
  },
)

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenuInfo: (_state, action) => {
      return action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(buildMenu.fulfilled, (state, action) => {
      return action.payload
    })
  },
})

export const { setMenuInfo } = menuSlice.actions
export default menuSlice.reducer
