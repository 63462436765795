import { post } from './request'

const DOMAIN = 'https://sysmanager.app.aohuacloud.com'

export const apiAddUpdatePriviledgeInfo = async ({
  id,
  name,
  route,
  parentId,
  order,
}) => {
  return await post(
    `${DOMAIN}/app/sysmanager/priviledge/addUpdatePriviledgeInfo`,
    { id, name, route, parentId, order },
  )
}

export const apiDeletePriviledgeInfo = async ({ id }) => {
  return await post(
    `${DOMAIN}/app/sysmanager/priviledge/deletePriviledgeInfo`,
    { id },
  )
}

export const apiQueryAllPriviledgeList = async () => {
  return await post(
    `${DOMAIN}/app/sysmanager/priviledge/queryAllPriviledgeList`,
  )
}

export const apiQueryUserPriviledgeInfo = async ({ userId }) => {
  return await post(
    `${DOMAIN}/app/sysmanager/priviledge/queryUserPriviledgeInfo`,
    { userId },
  )
}

export const apiUpdateUserPriviledgeInfo = async ({
  userId,
  priviledgeList,
  provinceId,
  cityId,
  countyId,
  schoolId,
}) => {
  return await post(
    `${DOMAIN}/app/sysmanager/priviledge/updateUserPriviledgeInfo`,
    {
      userId,
      priviledgeList,
      provinceId,
      cityId,
      countyId,
      schoolId,
    },
  )
}
