import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiQueryAllPriviledgeList } from '@/apis/api-sys-manager'

const initialState = []

export const getAllPriviledge = createAsyncThunk(
  'allPriviledgeSlice/getAllPriviledge',
  async () => {
    const res = await apiQueryAllPriviledgeList()
    if (res.result === 0) {
      return res.list
    }
    return []
  },
)

export const allPriviledgeSlice = createSlice({
  name: 'allPriviledge',
  initialState,
  reducers: {
    setAllPriviledgeList: (state, action) => {
      return action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPriviledge.fulfilled, (state, action) => {
      return action.payload
    })
  },
})

export const { setAllPriviledgeList } = allPriviledgeSlice.actions
export default allPriviledgeSlice.reducer
