import store from '@/store'

export function getParents(parentId, list) {
  const parents = []
  const parentIds = []

  let pid = parentId
  do {
    const item = list.find((v) => v.id === pid)
    if (item) {
      pid = item.parentId
      parents.push(item)
      parentIds.push(pid)
    } else {
      break
    }
  } while (pid !== 0)

  return { parents, parentIds }
}

export function buildMenuTree(list) {
  const getChildren = (pid) => {
    const children = list
      .filter((v) => v.parentId === pid)
      .sort((a, b) => a.order - b.order)

    for (let i = 0; i < children.length; ++i) {
      const tmp = getChildren(children[i].id)
      children[i].children = tmp.length > 0 ? tmp : undefined
    }

    return children
  }

  const tree = list
    .filter((v) => v.parentId === 0)
    .sort((a, b) => a.order - b.order)

  for (let i = 0; i < tree.length; ++i) {
    const tmp = getChildren(tree[i].id)
    tree[i].children = tmp.length > 0 ? tmp : undefined
  }

  return tree
}

export function buildAreaTree(list) {
  const map = {}
  for (const item of list) {
    let parentKey
    if (item.type === 1) {
      parentKey = '0'
    } else if (item.type === 2) {
      parentKey = `${item.province}`
    } else {
      parentKey = `${item.province}-${item.city}`
    }

    if (map[parentKey]) {
      map[parentKey].push(item)
    } else {
      map[parentKey] = [item]
    }
  }

  const tree = list.filter((v) => v.type === 1)
  for (let i = 0; i < tree.length; ++i) {
    if (tree[i].id === 0) {
      continue
    }
    const province = tree[i]
    const k1 = `${province.province}`
    if (map[k1]) {
      const children = map[k1]
      tree[i] = { ...province, children }

      for (let ii = 0; ii < children.length; ++ii) {
        const city = children[ii]
        const k2 = `${city.province}-${city.city}`
        if (map[k2]) {
          children[ii] = { ...city, children: map[k2] }
        }
      }
    }
  }

  return tree
}

export function expandCheckedPriviledge(priviledges) {
  if (priviledges.length === 1 && priviledges[0] === 0) {
    const menu = store.getState().menu
    const checkedKeys = []
    for (const item of menu) {
      checkedKeys.push(item.id)
    }

    return checkedKeys
  } else {
    return priviledges
  }
}

export function shrinkCheckedPriviledge(checked) {
  const helper = (list) => {
    for (const item of list) {
      if (checked.includes(item.id)) {
        ret.push(item.id)
      } else {
        if (item?.children?.length > 0) {
          helper(item.children)
        }
      }
    }
  }

  const menu = store.getState().menu
  let ret = []
  helper(menu)

  let isAll = true
  for (const item of menu) {
    if (!ret.includes(item.id)) {
      isAll = false
    }
  }

  if (isAll) {
    ret = [0]
  }

  return ret
}

export function getUserAreaList(areaList, userPriviledge) {
  if (userPriviledge.provinceId === 0) {
    return areaList
  }

  const list = []
  const tmpProvince = areaList.find((v) => v.id === userPriviledge.provinceId)
  if (tmpProvince) {
    list.push(tmpProvince)
  }

  if (userPriviledge.cityId === 0) {
    const tmpCityList = areaList.filter(
      (v) => v.type === 2 && v.province === userPriviledge.provinceId,
    )
    list.push(...tmpCityList)

    const tmpCountyList = areaList.filter(
      (v) => v.type === 3 && v.province === userPriviledge.provinceId,
    )
    list.push(...tmpCountyList)
  } else {
    const tmpCity = areaList.find(
      (v) => v.type === 2 && v.id === userPriviledge.cityId,
    )
    if (tmpCity) {
      list.push(tmpCity)
    }

    if (userPriviledge.countyId === 0) {
      const tmpCountyList = areaList.filter(
        (v) => v.type === 3 && v.city === userPriviledge.cityId,
      )
      list.push(...tmpCountyList)
    } else {
      const tmpCounty = areaList.find(
        (v) => v.type === 3 && v.id === userPriviledge.countyId,
      )
      if (tmpCounty) {
        list.push(tmpCounty)
      }
    }
  }

  return list
}
