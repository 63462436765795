import { useSelector } from 'react-redux'
import { Cascader } from 'antd'

export default function AreaPicker({ total, onChange, ...restProps }) {
  const area = useSelector((state) =>
    total ? state.area.totalTree : state.area.tree,
  )

  const handleChange = (value, selectedOptions) => {
    onChange && onChange(value, selectedOptions)
  }

  return (
    <Cascader
      options={area}
      expandTrigger='hover'
      changeOnSelect
      onChange={handleChange}
      {...restProps}
    />
  )
}
