import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  list: [],
  tree: [],
  totalList: [],
  totalTree: [],
}

export const areaSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    setAreaInfo: (state, action) => {
      return Object.assign(state, action.payload)
    },
  },
})

export const { setAreaInfo } = areaSlice.actions
export default areaSlice.reducer
