import { UserRole } from './constants'
import store from '@/store'

export function strIsNumber(str) {
  const reg = /^\d+$/
  return reg.test(str)
}

export function strIsPhone(str) {
  const reg = /^(?:(?:\+|00)86)?1\d{10}$/
  return reg.test(str)
}

export function isStudent(role) {
  return (role & UserRole.student) === UserRole.student
}

export function isParent(role) {
  return (role & UserRole.parent) === UserRole.parent
}

export function isTeacher(role) {
  return (role & UserRole.teacher) === UserRole.teacher
}

export function isClassMaster(role) {
  return (role & UserRole.classMaster) === UserRole.classMaster
}

export function isGradeManager(role) {
  return (role & UserRole.gradeManager) === UserRole.gradeManager
}

export function isSchoolManager(role) {
  return (role & UserRole.schoolManager) === UserRole.schoolManager
}

export function isSuperManager(role) {
  return (role & UserRole.superPriviledge) === UserRole.superPriviledge
}

export function getUserRoleNamesInSchool(role) {
  const roleNames = []

  if (role & UserRole.schoolManager) {
    roleNames.push('学校管理员')
  }
  if (role & UserRole.gradeManager) {
    roleNames.push('年级主任')
  }
  if (role & UserRole.classMaster) {
    roleNames.push('班主任')
  }
  if (role & UserRole.teacher) {
    roleNames.push('老师')
  }
  if (role & UserRole.parent) {
    roleNames.push('家长')
  }
  if (role & UserRole.student) {
    roleNames.push('学生')
  }

  return roleNames
}

export function getUserRoleNames(role) {
  const roleNames = getUserRoleNamesInSchool(role)
  if (role & UserRole.superPriviledge) {
    roleNames.push('超级管理员')
  }
  return roleNames
}

export function getPriviledgeNames(priviledges) {
  const allPriviledges = store.getState().allPriviledge
  const names = []
  for (let i = 0; i < priviledges.length; ++i) {
    if (priviledges[i] === 0) {
      names.push('全部')
      continue
    }
    const item = allPriviledges.find((v) => v.id === priviledges[i])
    if (item) {
      names.push(item.name)
    }
  }
  return names
}

export function getAreaValue({ province, city, county }) {
  const names = []
  const items = []
  const areaList = store.getState().area.list

  let item = areaList.find((v) => v.type === 1 && v.id === province)
  if (item) {
    names.push(item.name)
    items.push(item)
  }

  if (city) {
    item = areaList.find((v) => v.type === 2 && v.id === city)
    if (item) {
      names.push(item.name)
      items.push(item)
    }
  }

  if (county) {
    item = areaList.find((v) => v.type === 3 && v.id === county)
    names.push(item.name)
    items.push(item)
  }

  return { names, items }
}

export function getAreaName({ province, city, county }) {
  const areaList = store.getState().area.list
  if (province === 0) {
    return '全国'
  }

  let name = ''
  let item = areaList.find((v) => v.type === 1 && v.id === province)
  name += item?.name || ''

  if (city === 0) {
    return name
  }

  item = areaList.find((v) => v.type === 2 && v.id === city)
  name += item?.name || ''

  if (county === 0) {
    return name
  }

  item = areaList.find((v) => v.type === 3 && v.id === county)
  name += item?.name || ''

  return name
}

export function objectToList(
  obj,
  keyType = 'string', // string or number
  filedNames = { keyName: 'id', valueName: 'name' },
) {
  const keys = Object.keys(obj)
  const list = []
  for (const k of keys) {
    const keyValue = keyType === 'number' ? parseInt(k, 10) : k
    const item = {}
    item[filedNames.keyName] = keyValue
    item[filedNames.valueName] = obj[k]
    list.push(item)
  }
  return list
}
