export const APP_ID = 10000
export const FACE_APP_ID = 10

export const BloodType = {
  A: 1,
  B: 2,
  AB: 3,
  O: 4,
  RH: 5,
}

export const BloodTypeName = {
  1: 'A',
  2: 'B',
  3: 'AB',
  4: 'O',
  5: 'RH',
}

export const CardTypeName = {
  0: 'IC卡',
  1: '身份证',
}

export const ChineseNumber = {
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
  7: '七',
  8: '八',
  9: '九',
  10: '十',
}

export const ClassTypeName = {
  1: '行政班',
  2: '非行政班',
}

export const Gender = {
  0: '不明',
  1: '男',
  2: '女',
}

export const JoinMode = {
  forbidden: 1, // 禁止加入
  freedom: 2, // 自由加入
  approval: 4, // 批准加入
}

export const JoinModeName = {
  forbidden: '禁止加入',
  freedom: '自由加入',
  approval: '批准加入',
}

export const JoinModeNameById = {
  1: '禁止加入',
  2: '自由加入',
  4: '批准加入',
}

export const ModePropName = {
  10: '父亲',
  11: '母亲',
  12: '爷爷',
  13: '奶奶',
  14: '姥爷',
  15: '姥姥',
  20: '家长',
}

export const NameTypes = {
  User: 1,
  School: 2,
  Grade: 3,
  Class: 4,
  Topic: 5,
  LeaveType: 6,
  FlowerType: 7,
  FlagType: 8,
  Subject: 9,
}

export const NationalityName = {
  1: '中国',
  2: '外国',
}

export const PhaseType = {
  nurse: 1, // 幼儿园
  primary: 2, // 小学
  junior: 4, // 初中
  special: 8, // 职业中专（职业高中）
  senior: 16, // 高中
  college: 32, // 大专院校
}

export const PhaseName = {
  nurse: '幼儿园',
  primary: '小学',
  junior: '初中',
  special: '职高',
  senior: '高中',
  college: '大学',
}

export const PhaseNameById = {
  1: '幼儿园',
  2: '小学',
  4: '初中',
  8: '职高',
  16: '高中',
  32: '大学',
}

export const PoliticsName = {
  10: '中共党员',
  20: '中共预备党员',
  30: '共青团员',
  41: '中国国民党革命委员会',
  42: '中国民主同盟',
  43: '中国民主建国会',
  44: '中国民主促进会',
  45: '中国农工民主党',
  46: '中国致公党',
  47: '九三学社',
  48: '台湾民主自治同盟',
  60: '无党派人士',
  70: '群众',
}

export const SchoolPropertyName = {
  0: '无',
  1: '省直属',
  2: '市直属',
}

export const StoreNameKey = {
  1: 'user',
  2: 'school',
  3: 'grade',
  4: 'class',
  5: 'topic',
  6: 'leaveType',
  7: 'flowerType',
  8: 'flagType',
  9: 'subject',
}

export const PropName = {
  address: '家庭住址',
  areaId: '所属区域',
  avatar: '用户头像',
  birth: '生日',
  bloodType: '血型',
  cardExt: '卡扩展信息',
  cardNo: '卡号',
  cardType: '卡类型',
  certCode: '教师资格证/学籍号',
  classId: '班级ID',
  classMasterDate: '任班主任开始日期',
  coparation: '公司名称',
  education: '学历',
  email: '邮箱',
  faceRes: '人脸识别图片',
  face: '人脸特征码',
  interests: '兴趣爱好',
  inTime: '注册日期',
  introduce: '个人简介',
  gender: '性别',
  idCard: '身份证',
  loginTime: '登录日期',
  name: '姓名',
  nation: '民族',
  nationality: '国籍',
  native: '籍贯',
  partyTime: '入党日期',
  politics: '政治面貌',
  phone: '手机号',
  role: '用户角色',
  schoolId: '学校ID',
  teachDate: '任教开始日期',
  userId: '用户ID',
}

export const UserRole = {
  student: 0x01,
  parent: 0x02,
  teacher: 0x10,
  classMaster: 0x20,
  gradeManager: 0x40,
  schoolManager: 0x80,
  superPriviledge: 0x10000000,
}

export const WeekDays = [
  '星期日',
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六',
]
export const WeekSimpleNames = [
  '周一',
  '周二',
  '周三',
  '周四',
  '周五',
  '周六',
  '周日',
]
