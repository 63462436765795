import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = {
  class: {},
  flagTypes: {},
  flowerTypes: {},
  grade: {},
  leaveTypes: {},
  school: {},
  subjects: {},
  topic: {},
  user: {},
  nameRequestQueue: {},
}

const nameCacheSlice = createSlice({
  name: 'nameCache',
  initialState,
  reducers: {
    setCacheValue: (state, action) => {
      const { path, value } = action.payload
      const oldValue = _.get(state, path)
      _.set(state, path, { ...oldValue, ...value })
    },
    pushToNameRequestQueue: (state, action) => {
      const { type, id, schoolId } = action.payload
      const k = schoolId !== undefined ? `${type}-${schoolId}` : type
      if (state.nameRequestQueue[k]) {
        state.nameRequestQueue[k].push(id)
      } else {
        state.nameRequestQueue[k] = [id]
      }
    },
    clearNameRequestQueue: (state) => {
      state.nameRequestQueue = {}
    },
  },
})

export const { setCacheValue, pushToNameRequestQueue, clearNameRequestQueue } =
  nameCacheSlice.actions
export default nameCacheSlice.reducer
