import { apiAddProgramPublishInfo, apiGetSchoolInfo, apiQueryAllProgramInfoList, apiQueryAllProgramPublishInfoList, apiRemoveProgramPublishInfom } from "@/apis/api-banpai"
import { Button, Modal, Radio, Select, Space, Table, message } from "antd"
import { useEffect, useState } from "react"
import AreaPicker from "@/components/area-picker"

export default function AppPublishList(){

    const [pageIndex,setPageIndex] = useState(0)
    const [itemList,setItemList] = useState([])
    const [isShowEditorModal,setIsShowEditorModal] = useState(false)
    const [isShowDeleteModal,setIsShowDeleteModal] = useState(false)
    const [selectedItem,setSelectedItem] = useState({})
    const [appList,setAppList] = useState([])
    const [schoolList,setSchoolList] = useState([])

    const fetchList = async()=>{
        const res = await apiQueryAllProgramPublishInfoList({pageIndex,pageCount:10})
        if(res.result === 0){
            setItemList(res.data)
            return
        }
        message.error(res.msg)
    }

    const fetchAppList = async()=>{
        const res = await apiQueryAllProgramInfoList({pageIndex:0,pageCount:1000})
        if(res.result === 0){
            setAppList(res.data)
            return
        }
        message.error(res.msg)
    }

    const fetchSchool = async () => {
        // console.log('fetchSchool1',areaValue)
        const province = selectedItem?.provinceId || 0
        const city = selectedItem?.cityId || 0
        const county = selectedItem?.countyId || 0
        console.log("fetchSchool2",province,city,county)
        const res = await apiGetSchoolInfo({
          province,
          city,
          county,
          name:'',
          phaseType:0,
          pageIndex:1,
          pageSize:1000
        })
        if(res.result === 0){
          // console.log('fetchSchool3',res.data)
          setSchoolList(res.data)
        }else{
          message.error('获取学校列表错误，' + res.msg)
        }
    }

    const handleOkEditor = async()=>{
        const res = await apiAddProgramPublishInfo(selectedItem)
        if(res.result === 0){
            await fetchList()
            setIsShowEditorModal(false)
            return
        }
        message.error(res.msg)
    }

    const handleOkDelete = async()=>{
        const res = await apiRemoveProgramPublishInfom(selectedItem)
        if(res.result === 0){
            await fetchList()
            setIsShowDeleteModal(false)
            return
        }
        message.error(res.msg)
    }

    const columns = [
        {title:'id',key:'id',dataIndex:'id'},
        {title:'location',key:'location',render:(_,item)=>(
            <>
                {item.provinceId} - {item.cityId} - {item.countyId} - {item.schoolId}
            </>
        )},
        {
            title:'app',key:'app',render:(_,item)=>(
                <>
                    {appList.find((app)=>{
                        return app.id === item.programId
                    })?.name}
                </>
            )
        },
        {title:'类型',key:'type',render:(_,item)=>(
            <>
                {item.type === 0 ? '包含' : '排除'}
            </>
        )},
        {
            title:'action',key:'action',render:(_,item)=>(
                <>
                    <Space>
                        <a href onClick={(e)=>{
                            setSelectedItem(item)
                            setIsShowEditorModal(true)
                        }}>编辑</a>
                        <a href onClick={(e)=>{
                            setSelectedItem(item)
                            setIsShowDeleteModal(true)
                        }}>删除</a>
                    </Space>
                </>
            )
        }
    ]

    useEffect(()=>{
        fetchList()
    },[pageIndex])

    useEffect(()=>{
        fetchAppList()
    },[])

    
    useEffect(()=>{
        fetchSchool()
    },[selectedItem?.provinceId,selectedItem?.cityId,selectedItem?.countyId])


    return (<>

        <Space>
            <Button type="primary" onClick={(e)=>{
                setSelectedItem({
                    type:0
                })
                setIsShowEditorModal(true)
            }}>新增</Button>
            <Button type="primary" onClick={(e)=>{
                fetchList()
            }}>刷新</Button>
        </Space>
        <Table
            columns={columns}
            dataSource={itemList}
        />
    
        <Modal
            open={isShowEditorModal}
            onCancel={(e)=>{setIsShowEditorModal(false)}}
            onOk={handleOkEditor}
            title='编辑'
        >
            <Space direction="vertical">
                <span>应用</span>
                <Select
                    value={selectedItem?.programId}
                    style={{width:100}}
                    options={appList?.map((item)=>({
                        label:item.name,
                        value:item.id
                    }))}
                    onChange={(v)=>{
                        setSelectedItem({
                            ...selectedItem,
                            programId:v
                        })
                    }}
                />

                <AreaPicker 
                        style={{width:300}}
                        value={[selectedItem?.provinceId || 0,selectedItem?.cityId || 0, selectedItem?.countyId || 0]}
                        onChange={(v,options)=>{
                            setSelectedItem({
                                ...selectedItem,
                                provinceId:v[0] || 0,
                                cityId:v[1] || 0,
                                countyId:v[2] || 0,
                                schoolId:0
                        })      
                    }}
                    />
                    <Select 
                        style={{minWidth:100}}
                        value={selectedItem?.schoolId || 0} 
                        onSelect={(id)=>{setSelectedItem({
                            ...selectedItem,
                            schoolId:id
                        })}}
                        options={schoolList.map((item)=>(
                            {
                                label:item.name,
                                value:item.id
                            }
                        ))}
                   />

                   <div>类型</div>
                   <Radio.Group 
                        value={selectedItem?.type || 0} 
                        onChange={(e)=>{
                    setSelectedItem({
                        ...selectedItem,
                        type:e.target.value
                    })
                   }}>
                            <Radio value={0}>包含</Radio>
                            <Radio value={1}>排除</Radio>
                   </Radio.Group>
            </Space>

        </Modal>

        <Modal open={isShowDeleteModal}
        
                            title='删除确认'
                            onCancel={(e)=>{
                                setIsShowDeleteModal(false)
                            }}
                            onOk={handleOkDelete}
        
        >
            <Space>
                是否删除 {selectedItem?.id} , {appList.find((app)=>{
                    return app.id === selectedItem?.programId
                })?.name} 在 {selectedItem?.provinceId} {selectedItem?.cityId} {selectedItem?.countyId}
            </Space>

        </Modal>

    </>)
}