import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiQueryUserPriviledgeInfo } from '@/apis/api-sys-manager'

const initialState = {
  priviledge: [],
  provinceId: null,
  cityId: null,
  countyId: null,
  schoolId: null,
}

export const getUserPriviledge = createAsyncThunk(
  'userPriviledgeSlice/getUserPriviledge',
  async (userId) => {
    const res = await apiQueryUserPriviledgeInfo({ userId })
    if (res.result === 0) {
      return {
        priviledge: res.data.priviledge,
        provinceId: res.data.provinceId,
        cityId: res.data.cityId,
        countyId: res.data.countyId,
        schoolId: res.data.schoolId,
      }
    }
    return {}
  },
)

export const userPriviledgeSlice = createSlice({
  name: 'userPriviledge',
  initialState,
  reducers: {
    setPriviledgeInfo: (state, action) => {
      return Object.assign(state, action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserPriviledge.fulfilled, (state, action) => {
      Object.assign(state, action.payload)
    })
  },
})

export const { setPriviledgeInfo } = userPriviledgeSlice.actions
export default userPriviledgeSlice.reducer
