import {  apiQueryAllDeviceShutdownInfo } from "@/apis/api-banpai"
import { Button, Modal, Space, Table, message } from "antd"
import { useEffect, useState } from "react"
import { durationFormat } from "./duration-format-utils"



export default function ShutdownList(){

    const [pageIndex,setPageIndex] = useState(0)
    const [shutdownList,setShutdownList] = useState([])
    const [isShowEditorModal,setIsShowEditorMoal] = useState(false)
    const [isShowDeleteModal,setIsShowDeleteModal] = useState(false)
    const [selectedItem,setSelectedItem] = useState({})

    const fetchList = async ()=>{
        const res = await apiQueryAllDeviceShutdownInfo({pageIndex,pageCount:10})
        if(res.result !== 0){
            message.error(res.msg)
            return 
        }
        setShutdownList(res.data)
    }

    const handleOkEditor = async()=>{
        // const res = await apiaddshu
    }

    const columns = [
        {title:'id',key:'id',dataIndex:'id'},
        {title:'location',key:'location',render:(_,info)=>(
            <>
            {info.provinceId}-{info.cityId}-{info.countyId}-{info.schoolId}-{info.gradeId}-{info.classId}
            </>
        )},
        {
            title:'dayOfweek',key:'dayOfWeek',render:(_,info)=>(
                <>
                    {info.dayOfWeek}
                </>
            )
        },
        {
            title:'time',key:'time',render:(_,info)=>(
                <>
                    {durationFormat(info.startupTime)} - {durationFormat(info.shutdownTime)}
                </>
            )
        },
        {
            title:'action',key:'action',render:(_,info)=>(
                <>
                    <Space>
                        <a href >编辑</a>
                        <a href >删除</a>
                    </Space>
                </>
            )
        }
    ]


    useEffect(()=>{
        fetchList()
    },[])

    return(<>
        
        <Space>
            <Button 
                type="primary"
                onClick={(e)=>{
                    setIsShowEditorMoal(true)
                    setSelectedItem({})
                }}
            >新增</Button>
            <Button type="primary" onClick={(e)=>{
                fetchList()
            }}>刷新</Button>
        </Space>

        <Table dataSource={shutdownList} columns={columns}>

        </Table>

        <Modal
            open={isShowEditorModal}
            onCancel={(e)=>{
                setIsShowEditorMoal(false)
            }}

        >

        </Modal>

    </>)
}