import { apiQueryProgramShowList } from "@/apis/api-banpai"
import { Button, Table, message } from "antd"
import { useEffect, useState } from "react"
import { durationFormat } from "./duration-format-utils"


export default function DeviceDetailProgram({deviceInfo}){

    const [programInfoList,setProgramInfoList] = useState([])
    const columns = [
        {title:'ID',key:'id',dataIndex:'id'},
        {title:'name',key:'name',dataIndex:'name'},
        {title:'dayOfWeek',key:'dayOfWeek',dataIndex:'dayOfWeek'},
        {title:'priority',key:'priority',dataIndex:'priority'},
        {title:'app',key:'app',render:(_,info)=>(
            <>{info.bundleId} - {info.scheme} - {info.param} - {info.url}</>
        )},
        {title:'time',key:'time',render:(_,info)=>(
            <>{durationFormat(info.cycleStartTime)} - {durationFormat(info.cycleEndTime)}</>
        )}
    ]

    const fetchProgramList = async ()=>{
        const res = await apiQueryProgramShowList({uuid:deviceInfo.uuid})
        if(res.result !==0){
            message.error('获取节目计划失败，' + res.msg)
            return
        }
        setProgramInfoList(res.data)
    }

    useEffect(()=>{
        fetchProgramList()
    },[])

    return (
        <>
            <Button type="primary" onClick={(e)=>{
                fetchProgramList()
            }}>刷新</Button>
            <Table columns={columns} dataSource={programInfoList}></Table>
        </>

    )
}