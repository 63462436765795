import { apiQueryDeviceDetailInfo } from "@/apis/api-banpai"
import { Row, Space, Tabs, message } from "antd"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import DeviceDetailBaseInfo from "./device-detail-baseinfo"
import DeviceDetailRemote from "./device-detail-remote"
import DeviceDetailShell from "./device-detail-shell"
import DeviceShutDown from "./device-detail-shutdown"
import DeviceDetailProgram from "./device-detail-program"


export default function DeviceDetail(){

    let {uuid} = useParams()
    const [deviceInfo,setDeviceInfo] = useState({})


    const tabItems = [
        {
          key: '1',
          label: '基本信息',
          children: (<DeviceDetailBaseInfo deviceInfo={deviceInfo}/>),
        },
        {
          key: '2',
          label: '远程管理',
          children: (<DeviceDetailRemote deviceInfo={deviceInfo}/>),
        },
        {
          key: '3',
          label: '远程脚本',
          children: (<DeviceDetailShell deviceInfo={deviceInfo}/>),
        },
        {
          key:'4',
          label:'开关机计划',
          children:(<DeviceShutDown deviceInfo={deviceInfo}/>),
        },
        {
          key:'5',
          label:'节目展示计划',
          children:(<DeviceDetailProgram deviceInfo={deviceInfo}/>),
        }
      ]

    const fetchDeviceDetailInfo = async ()=>{
        const res = await apiQueryDeviceDetailInfo({uuid})
        if(res.result !== 0){
            message.error("获取设备详情失败，" + res.msg)
        }
        setDeviceInfo(res.data)
    }

    useEffect(()=>{
       fetchDeviceDetailInfo()
    },[])


    return (<Space direction="vertical">
        <Space>
        <span>设备管理</span> - <span>设备详情</span> - <span>{uuid}</span> <span>{deviceInfo?.serialNo}</span>
        </Space>

            <Tabs defaultActiveKey="1" items={tabItems} />
    
    </Space>)

}