import { Col, Row, Space } from "antd";


export default function DeviceDetailBaseInfo({deviceInfo}){


    return(
        <Space direction="vertical">
            
            
            <Space>
                UUID : {deviceInfo.uuid} 
            </Space>
            <Space>
            serialNo {deviceInfo?.serialNo}
            </Space>
            <Space>
                SchoolID: {deviceInfo.schoolId} 
            </Space>
            <Space>
                是否在线： {deviceInfo.online === 0 ? '不在线' : '在线'}
            </Space>

        </Space>
    )
}