import { configureStore } from '@reduxjs/toolkit'
import allPriviledgeReducer from './all-priviledge'
import areaReducer from './area'
import configReducer from './config'
import menuReducer from './menu'
import nameCacheReducer from './name-cache'
import totalMenuReducer from './total-menu'
import userInfoReducer from './user-info'
import userPriviledgeReducer from './user-priviledge'
import userSearcherReducer from './user-searcher'

const store = configureStore({
  reducer: {
    allPriviledge: allPriviledgeReducer,
    area: areaReducer,
    config: configReducer,
    menu: menuReducer,
    nameCache: nameCacheReducer,
    totalMenu: totalMenuReducer,
    userInfo: userInfoReducer,
    userPriviledge: userPriviledgeReducer,
    userSearcher: userSearcherReducer,
  },
})

export default store
