import { apiAddProductType, apiQueryAllProductTypeList, apiRemoveProductType } from "@/apis/api-banpai";
import { Button, Input, Modal, Space, Table, message } from "antd";
import { useEffect, useState } from "react";



export default function ProductTypeList(){

    const [pageIndex,setPageIndex] = useState(0)
    const [productTypeList,setProductTypeList] = useState([])
    const [selectedProductType,setSelectedProductType] = useState({})
    const [isShowEditorModal,setIsShowEditorModal] = useState(false)
    const [isShowDeleteModal,setIsShowDeleteModal] = useState(false)

    const fetchList = async ()=>{
        const res = await apiQueryAllProductTypeList({pageIndex,pageCount:10})
        if(res.result !== 0){
            message.error('拉取产品类型列表失败' + res.msg)
            return;
        }
        setProductTypeList(res.data)
    }

    const handleOkEditor = async ()=>{
        const res = await apiAddProductType(selectedProductType)
        if(res.result !== 0){
            message.error('添加修改失败' + res.msg)
            return
        }
        fetchList()
        setIsShowEditorModal(false)
    }

    const handleOkDelete = async ()=>{
        const res = await apiRemoveProductType(selectedProductType)
        if(res.result !== 0){
            message.error('删除失败' + res.msg)
            return
        }
        fetchList()
        setIsShowDeleteModal(false)
    }

    useEffect(()=>{
        fetchList()
    },[pageIndex])

    const columns = [
        {title:'id',key:'id',dataIndex:'id'},
        {title:'name',key:'name',dataIndex:'name'},
        {title:'createTime',key:'createTime',dataIndex:'createTime'},
        {title:'creator',key:'creator',dataIndex:'creator'},
        {title:'action',key:'action',render:(_,info)=>(<>
            <Space>
                <a href onClick={(e)=>{
                    setIsShowEditorModal(true)
                    setSelectedProductType({
                        ...info,
                        typeName:info.name
                        
                    })
                }}>编辑</a>
                <a href onClick={(e)=>{
                    setIsShowDeleteModal(true)
                    setSelectedProductType(info)
                }}>删除</a>
            </Space>
        </>)}
    ]

    return(<>
        <Button type="primary" onClick={(e)=>{
            setSelectedProductType({})
            setIsShowEditorModal(true)
        }}>新增</Button>
        <Table dataSource={productTypeList} columns={columns}></Table>


        <Modal open={isShowEditorModal}
            title='产品类型编辑'
            onCancel={(e)=>{
                setIsShowEditorModal(false)
            }}
            onOk={handleOkEditor}
        >

            <div>产品类型名称：</div>
            <Input value={selectedProductType?.typeName} onChange={(e)=>{
                setSelectedProductType({
                    ...selectedProductType,
                    typeName:e.target.value
                })
            }}/>

        </Modal>

        <Modal open={isShowDeleteModal}
            title='删除确认'
            onCancel={(e)=>{
                setIsShowDeleteModal(false)
            }}
            onOk={handleOkDelete}
        >
            <Space>
                <p>您是否确认删除 {selectedProductType.id} , {selectedProductType.name}</p>
            </Space>
            
        </Modal>

    </>)
}