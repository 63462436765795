
export function durationFormat(timeInSecond){

    const hour = parseInt(timeInSecond/3600)
    const minute = parseInt((timeInSecond%3600)/60)
    const second = parseInt((timeInSecond % 3600) % 60)

    let result = ''
    if(hour < 10){
        result = '0' + hour
    }else{
        result = hour
    }
    result += ':'
    if(minute < 10){
        result += ('0' + minute)
    }else{
        result += minute
    }
    result += ':'
    if(second < 10){
        result += ('0' + second)
    }else{
        result += second
    }
    return result
}

export function dayOfWeekString(dayOfWeek){
    let result = ''
    if(dayOfWeek & 1 === 1){
        result += '周一'
    }
    if(dayOfWeek & 2 === 2){
        result += '周二'
    }
    if(dayOfWeek & 4 === 4){
        result += '周三'
    }
    if(dayOfWeek & 8 === 8){
        result += '周四'
    }
    if(dayOfWeek & 16 === 16){
        result += '周五'
    }
    if(dayOfWeek & 32 === 32){
        result += '周六'
    }
    if(dayOfWeek & 64 === 64){
        result += '周日'
    }
    return result
}