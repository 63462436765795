import { apiExecDeviceOnlineCommand } from "@/apis/api-banpai";
import { Button, Image, Input, InputNumber, Radio, Row, message } from "antd";
import { useState } from "react";


export default function DeviceDetailRemote({deviceInfo}){

    const [systeUiFlag,setSystemUiFlag] = useState(1)
    const [imageBase64,setImageBase64] = useState('')

    const fetchCommand = async (command,params) =>{
        const res = await apiExecDeviceOnlineCommand({
            uuid:deviceInfo.uuid,
            command:command,
            params:params
        })
        if(res.result !== 0){
            message.error('远程操作执行失败' + command + "," + res.msg)
            return 
        }
        return res;
    }

    const handleScreenCapClick = async ()=>{
       const res =  await fetchCommand('screencap','{sampleSize:2,quality:90}')
       console.log('handleScreenCapClick',res)
       setImageBase64('data:image/jpeg;base64,' + res.content)
    }

    const handleSystemUiClick = ()=>{
        if(systeUiFlag === 0){
            fetchCommand('systemui','{\"enable\":\"true\"}')
        }else{
            fetchCommand('systemui','{\"enable\":\"false\"}')
        }
    }

    const handleDeviceRebootClick = ()=>{
        fetchCommand('reboot',`{"delay":${delay}}`)
    }

    const handleDeviceShutdownClick = ()=>{
        fetchCommand('shutdown',`{"delay":${delay}}`)
    }

    const [delay,setDelay] = useState(30)

    return (
        <>
        <Row>
        <Radio.Group onChange={(e)=>setSystemUiFlag(e.target.value)} value={systeUiFlag}>
                <Radio value={0}>开</Radio>
                <Radio value={1}>关</Radio>
            </Radio.Group>

            <Button onClick={handleSystemUiClick}>状态栏开关</Button>
        </Row>
        <Row>
            <InputNumber value={delay} onChange={(v)=>{
                setDelay(v)
            }}/>
            <Button onClick={handleDeviceRebootClick}>重启设备</Button> 
            <Button onClick={handleDeviceShutdownClick}>设备关机</Button>
        </Row>
            <Row>
            <Button onClick={handleScreenCapClick} type="primary">设备截屏</Button>
            <Image src={imageBase64}/>

            </Row>
            
        </>
    )
}