import { apiExecDeviceOnlineCommand } from "@/apis/api-banpai";
import { Button, Input, message } from "antd";
import { useState } from "react";

export default function DeviceDetailShell({deviceInfo}){

    const [command,setCommand] = useState('')
    const [result,setResult] = useState('')

    const fetchCommand = async (command,params) =>{
        const res = await apiExecDeviceOnlineCommand({
            uuid:deviceInfo.uuid,
            command:command,
            params:params
        })
        if(res.result !== 0){
            message.error('远程操作执行失败' + command + "," + res.msg)
            return 
        }
        return res;
    }


    const handleRunClick = async () =>{
        const res = await fetchCommand('bash',`{"cmd":"${command}"}`)
        if(res.result !== 0){
            message.error('执行失败')
            return
        }
        setResult(res.content)
    }

    return(<>
        
        <Input value={command} onChange={(e)=>{
            setCommand(e.target.value)
        }}></Input>

        <Button type="primary" onClick={handleRunClick}>Run</Button>

        <p>{result}</p>

    </>)
}