import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      return Object.assign(state, action.payload)
    },
    clearUserInfo: (state) => {
      return {}
    },
  },
})

export const { setUserInfo, clearUserInfo } = userInfoSlice.actions
export default userInfoSlice.reducer
