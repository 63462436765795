import { apiAddProgramInfo, apiQueryAllProgramInfoList, apiRemoveProgramInfo } from "@/apis/api-banpai";
import { Button, Input, InputNumber, Modal, Radio, Row, Space, Table, message ,Typography} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
const { Text } = Typography

export default function AppList(){

    const [pageIndex,setPageIndex] = useState(0)
    const [appList,setAppList] = useState([])

    const columns = [
        {title:'id',key:'id',dataIndex:'id'},
        {title:'name',key:'name',dataIndex:'name'},
        {title:'desc',key:'desc',dataIndex:'desc'},
        {title:'version',key:'version',dataIndex:'versionCode'},
        // {title:'bundleId',key:'bundleId',dataIndex:'bundleId'},
        // {title:'installUrl',key:'installUrl',dataIndex:'installUrl'},
        {title:'启动方式',key:'params',render:(_,item)=>(
            <Space  direction="vertical" style={{maxWidth:300}}>
                <Text>bundleId : {item.bundleId}</Text>
                <Text>scheme : {item.scheme}</Text>
                <Text>param : {item.param}</Text>
                <Text>url : {item.url}</Text>
            </Space>
        )},
        // {title:'scheme',key:'scheme',dataIndex:'scheme'},
        // {title:'param',key:'param',dataIndex:'param'},
        // {title:'url',key:'url',dataIndex:'url'},
        {title:'action',key:'action',render:(_,appInfo)=>(
            <Space>
                <a href onClick={(e)=>{
                    setSelectedApp(appInfo)
                    setIsShowEditorModal(true)
                }}>编辑</a>
                <a href onClick={(e)=>{
                    setSelectedApp(appInfo)
                    setIsShowDeleteModal(true)
                }}>删除</a>
            </Space>
        )}
    ]

    const fetchAllList = async ()=>{
        const res = await apiQueryAllProgramInfoList({pageIndex,pageCount:10})
        if(res.result !== 0){
            message.error('获取应用列表失败,' + res.msg)
            return
        }
        setAppList(res.data)
    }

    useEffect(()=>{
        fetchAllList()
    },[])

    const [isShowEditorModal,setIsShowEditorModal] = useState(false)
    const [isShowDeleteModal,setIsShowDeleteModal] = useState(false)
    const [selectedApp,setSelectedApp] = useState({})

    const handleOkEditor = async ()=>{
        const res = await apiAddProgramInfo(selectedApp)
        if(res.result !== 0){
            message.error('更新app失败,' + res.msg)
            return 
        }
        fetchAllList()
        setIsShowEditorModal(false)
    }

    const handleOkDelete = async ()=>{
        const res = await apiRemoveProgramInfo(selectedApp)
        if(res.result !== 0){
            message.error(res.msg)
            return
        }
        fetchAllList()
        setIsShowDeleteModal(false)
    }

    return (<>
    
    <Button type="primary" onClick={(e)=>{
            setSelectedApp({})
            setIsShowEditorModal(true)
        }}>新增</Button>

        <Table columns={columns} dataSource={appList}>

        </Table>

       

        <Modal
            title="编辑"
            open={isShowEditorModal}
            onOk={handleOkEditor}
            okText="确定"
            onCancel={(e)=>{setIsShowEditorModal(false)}}
        >
            <Space direction="vertical">
                <Space>
                <span>应用名称 ： </span>
                <Input value={selectedApp?.name} onChange={(e)=>{
                    setSelectedApp({
                        ...selectedApp,
                        name:e.target.value
                    })
                }}></Input>

                </Space>

                <Row>
                <span>应用描述：</span>
                <Input value={selectedApp?.desc} onChange={(e)=>{
                    setSelectedApp({
                        ...selectedApp,
                        desc:e.target.value
                    })
                }}></Input>
                </Row>

                <Space>
                    <span>打开方式 : </span>
                    <Radio.Group value={selectedApp?.type} onChange={(e)=>{
                        setSelectedApp({
                            ...selectedApp,
                            type:e.target.value
                        })
                    }}>
                        <Radio value={1}>安装包</Radio>
                        <Radio value={2}>URL</Radio>
                    </Radio.Group>
                </Space>

                <Space>
                <span>版本号 ： </span>
                <InputNumber value={selectedApp?.versionCode}
                    onChange={(v)=>{
                        setSelectedApp({
                            ...selectedApp,
                            versionCode:v
                        })
                    }}
                ></InputNumber>
                <span>版本名称 ： </span>
                <Input value={selectedApp?.versionName}
                    onChange={(e)=>{
                        setSelectedApp({
                            ...selectedApp,
                            versionName:e.target.value
                        })
                    }}
                ></Input>
                </Space>
                

                <span>bundleId</span>
                <Input value={selectedApp?.bundleId}
                    onChange={(e)=>{
                        setSelectedApp({
                            ...selectedApp,
                            bundleId:e.target.value
                        })
                    }}
                ></Input>



                <span>scheme</span>
                <Input value={selectedApp?.scheme}
                    onChange={(e)=>{
                        setSelectedApp({
                            ...selectedApp,
                            scheme:e.target.value
                        })
                    }}
                ></Input>



<span>param</span>
                <TextArea  value={selectedApp?.param}  style={{ width: 450 }} rows={6}
                    onChange={(e)=>{
                        setSelectedApp({
                            ...selectedApp,
                            param:e.target.value
                        })
                    }}
                ></TextArea >
<span>url</span>
                <Input value={selectedApp?.url}
                    onChange={(e)=>{
                        setSelectedApp({
                            ...selectedApp,
                            url:e.target.value
                        })
                    }}
                ></Input>

                <Row>
                <span>安装类型 : </span>
                    <Radio.Group value={selectedApp?.installType} onChange={(e)=>{
                        setSelectedApp({
                            ...selectedApp,
                            installType:e.target.value
                        })
                    }}>
                        <Radio value={1}>无需安装</Radio>
                        <Radio value={2}>下载安装</Radio>
                    </Radio.Group>
                </Row>
                <span>安装地址：</span>
                <TextArea value={selectedApp?.installUrl} rows={3}
                    onChange={(e)=>{
                        setSelectedApp({
                            ...selectedApp,
                            installUrl:e.target.value
                        })
                    }}
                ></TextArea>


               


            </Space>

        </Modal>
        
    
        <Modal 
            open={isShowDeleteModal} 
            title="删除确认" 
            onCancel={(e)=>{
                setIsShowDeleteModal(false)
            }}
            onOk={handleOkDelete}>
            <Space>
                确认删除 {selectedApp?.name} , id : {selectedApp?.id}
            </Space>
        </Modal>

    </>)
}