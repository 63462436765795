import React, { useEffect } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setConfig } from '@/store/config'
import { apiGetAppConfigInfo } from '@/apis/api-login'
import RequireAuth from '@/components/require-auth'
import { history } from '@/shared/history'
import DeviceDetail from '../device-manager/device-detail'
import ProviderList from '../device-manager/provider-list'
import AppList from '../device-manager/app-list'
import ShutdownList from '../device-manager/shutdown-list'
import ProgramList from '../device-manager/program-list'
import ProductTypeList from '../device-manager/product-type-list'
import AppPublishList from '../device-manager/app-publish-list'
import ProductList from '../device-manager/product-list'

const Login = React.lazy(() => import('@/pages/login'))
const Layout = React.lazy(() => import('@/pages/layout'))
const Home = React.lazy(() => import('@/pages/home'))
const MenuEditor = React.lazy(() => import('@/pages/menu-editor'))
const UserPriviledge = React.lazy(() => import('@/pages/user-priviledge'))
const UserManager = React.lazy(() => import('@/pages/user-manager'))
const UserDetail = React.lazy(() => import('@/pages/user-manager/user-detail'))
const UserEditor = React.lazy(() => import('@/pages/user-manager/user-editor'))
const SchoolManager = React.lazy(() => import('@/pages/school-manager'))
const DeviceManager = React.lazy(()=>import('@/pages/device-manager'))
const SchoolDetail = React.lazy(() =>
  import('@/pages/school-manager/school-detail'),
)
const SchoolEditor = React.lazy(() =>
  import('@/pages/school-manager/school-editor'),
)
const TeacherEditor = React.lazy(() =>
  import('@/pages/school-manager/teacher-editor'),
)
const StudentEditor = React.lazy(() =>
  import('@/pages/school-manager/student-editor'),
)
const ClassEditor = React.lazy(() =>
  import('@/pages/school-manager/class-editor'),
)
const ClassDetail = React.lazy(() =>
  import('@/pages/school-manager/class-detail'),
)

function App() {
  const navigate = useNavigate()
  const location = useLocation()
  history.navigate = navigate
  history.location = location

  const dispatch = useDispatch()
  const token = useSelector((state) => state.userInfo.token)

  useEffect(() => {
    const getConfig = async () => {
      const res = await apiGetAppConfigInfo()
      if (res.result === 0) {
        try {
          const config = JSON.parse(res.data.content)
          dispatch(setConfig(config))
        } catch (err) {
          console.err(err.message)
        }
      }
    }

    getConfig()
  }, [token])

  return (
    <Routes>
      <Route
        path='/'
        element={
          <React.Suspense fallback={<>...</>}>
            <RequireAuth>
              <Layout />
            </RequireAuth>
          </React.Suspense>
        }
      >
        <Route
          index
          element={
            <React.Suspense fallback={<>...</>}>
              <Home />
            </React.Suspense>
          }
        />
        <Route
          path='menu-editor'
          element={
            <React.Suspense fallback={<>...</>}>
              <MenuEditor />
            </React.Suspense>
          }
        />
        <Route
          path='user-priviledge/:searchText?'
          element={
            <React.Suspense fallback={<>...</>}>
              <UserPriviledge />
            </React.Suspense>
          }
        />
        <Route
          path='user-manager/:tabIndex?'
          element={
            <React.Suspense fallback={<>...</>}>
              <UserManager />
            </React.Suspense>
          }
        />
        <Route
          path='user-detail/:userId'
          element={
            <React.Suspense fallback={<>...</>}>
              <UserDetail />
            </React.Suspense>
          }
        />
        <Route
          path='user-editor/:userId?'
          element={
            <React.Suspense fallback={<>...</>}>
              <UserEditor />
            </React.Suspense>
          }
        />
        <Route
          path='school-manager'
          element={
            <React.Suspense fallback={<>...</>}>
              <SchoolManager />
            </React.Suspense>
          }
        />
        <Route
          path='device-manager'
          element={
            <React.Suspense fallback={<>...</>}>
              <DeviceManager />
            </React.Suspense>
          }
        />
        <Route 
          path='device-detail/:uuid'
          element={
            <React.Suspense fallback={<>...</>}>
              <DeviceDetail />
            </React.Suspense>
          }
        />
        <Route
          path='provider-list'
          element={
            <React.Suspense fallback={<>...</>}>
              <ProviderList/>
            </React.Suspense>
          }
        />
        <Route 
          path='app-list'
          element={
            <React.Suspense fallback={<></>}>
              <AppList/>
            </React.Suspense>
          }
        />
        <Route
          path="program-list"
          element={
            <React.Suspense fallback={<></>}>
              <ProgramList/>
            </React.Suspense>
          }
        />
        <Route
          path='shutdown-list'
          element={
            <React.Suspense fallback={<></>}>
              <ShutdownList/>
            </React.Suspense>
          }
        />
        <Route
          path='product-type-list'
          element={
            <React.Suspense fallback={<></>}>
              <ProductTypeList/>
            </React.Suspense>
          }
        />
        <Route 
          path='product-list'
          element={
            <React.Suspense fallback={<></>}>
              <ProductList/>
            </React.Suspense>
          }
        />
        <Route
          path='publish-list'
          element={
            <React.Suspense fallback={<></>}>
              <AppPublishList/>
            </React.Suspense>
          }
        />

        <Route
          path='school-editor/:schoolId?'
          element={
            <React.Suspense fallback={<>...</>}>
              <SchoolEditor />
            </React.Suspense>
          }
        />
        <Route
          path='school-detail/:schoolId/:key?'
          element={
            <React.Suspense fallback={<>...</>}>
              <SchoolDetail />
            </React.Suspense>
          }
        />
        <Route
          path='teacher-editor/:schoolId/:classId/:teacherId/:isNew?'
          element={
            <React.Suspense fallback={<>...</>}>
              <TeacherEditor />
            </React.Suspense>
          }
        />
        <Route
          path='class-editor/:schoolId/:classId?'
          element={
            <React.Suspense fallback={<>...</>}>
              <ClassEditor />
            </React.Suspense>
          }
        />
        <Route
          path='class-detail/:classId/:key?'
          element={
            <React.Suspense fallback={<>...</>}>
              <ClassDetail />
            </React.Suspense>
          }
        />
        <Route
          path='student-editor/:schoolId/:gradeId/:classId/:studentId?'
          element={
            <React.Suspense fallback={<>...</>}>
              <StudentEditor />
            </React.Suspense>
          }
        />
      </Route>

      <Route
        path='/login'
        element={
          <React.Suspense fallback={<>...</>}>
            <Login />
          </React.Suspense>
        }
      />

      <Route path='*' element={<div>404</div>} />
    </Routes>
  )
}

export default App
