import { apiQueryDeviceShutdownInfo } from "@/apis/api-banpai"
import { Button, Table, message } from "antd"
import { useEffect, useState } from "react"
import { durationFormat } from "./duration-format-utils"


export default function DeviceShutDown({deviceInfo}){

    const [shutdownPlan,setShutDownPlan] = useState([])
    const columns = [
        {title:'日期',key:'day',dataIndex:'dayOfWeek'},
        {title:'开机',key:'startup',render:(_,info) => (
            <>{durationFormat(info.startup)}</>
        )},
        {title:'关机',key:'shutdown',render:(_,info)=>(
            <>{durationFormat(info.shutdown)}</>
        )}
    ]

    const fetchShutDownPlan = async ()=>{
        const res = await apiQueryDeviceShutdownInfo({uuid:deviceInfo.uuid})
        if(res.result !== 0){
            message.error('获取开关机计划失败，' + res.msg)
            return
        }
        setShutDownPlan(res.data)
    }

    useEffect(()=>{
        fetchShutDownPlan()
    },[])

    return (<>
        <p>{deviceInfo.uuid}</p>
        <Button type="primary" onClick={(e)=>{
            fetchShutDownPlan()
        }}>刷新</Button>
        <Table columns={columns} dataSource={shutdownPlan}></Table>
    
    </>)

}