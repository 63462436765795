import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { strIsNumber, strIsPhone } from '@/shared/utils'
import { apiGetUserInfoById, apiGetUserInfoByPhone } from '@/apis/api-banpai'

const initialState = {
  searchText: '',
  userList: [],
}

export const searchUser = createAsyncThunk(
  'userSeacherSlice/seachUser',
  async (text) => {
    if (strIsPhone(text)) {
      const res = await apiGetUserInfoByPhone({ phoneNumber: text })
      if (res.result === 0) {
        return { searchText: text, userList: [res.data] }
      }
    } else if (strIsNumber(text)) {
      const res = await apiGetUserInfoById({ userId: parseInt(text, 10) })
      if (res.result === 0) {
        return { searchText: text, userList: [res.data] }
      }
    }
    return { searchText: text, userList: [] }
  },
)

export const userSearcherSlice = createSlice({
  name: 'userSearcher',
  initialState,
  reducers: {
    setSearchText: (state, action) => {
      state.searchText = action.payload
    },
    setSearchUserResult: (state, action) => {
      return Object.assign(state, action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchUser.fulfilled, (state, action) => {
      Object.assign(state, action.payload)
    })
  },
})

export const { setSearchText, setSearchUserResult } = userSearcherSlice.actions
export default userSearcherSlice.reducer
