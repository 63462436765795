import { post } from './request'
import _ from 'lodash'

const DOMAIN = 'https://banpai.app.aohuacloud.com'

export const apiGetAdminInfo = async ({ schoolId }) => {
  return await post(`${DOMAIN}/classService/api/admin/getAdminInfo`, {
    schoolId,
  })
}

export const apiAddAdmin = async ({ schoolId, gradeId, targetId }) => {
  return await post(`${DOMAIN}/classService/api/admin/addAdmin`, {
    schoolId,
    gradeId,
    targetId,
  })
}

export const apiCancelAdmin = async ({ schoolId, gradeId, targetId }) => {
  return await post(`${DOMAIN}/classService/api/admin/cancelAdmin`, {
    schoolId,
    gradeId,
    targetId,
  })
}

export const apiGetGradeAdminList = async ({ schoolId, gradeId }) => {
  return await post(`${DOMAIN}/classService/api/admin/getGradeAdminList`, {
    schoolId,
    gradeId,
  })
}

export const apiGetChildrenInfo = async ({ userId }) => {
  return await post(`${DOMAIN}/classService/api/child/getChildrenInfo`, {
    userId,
  })
}

export const apiGetChildParents = async ({ userId }) => {
  return await post(`${DOMAIN}/classService/api/child/getChildParents`, {
    userId,
  })
}

export const apiGetAllClassInfo = async ({
  schoolId,
  gradeId,
  type,
  pageIndex,
  pageSize,
}) => {
  return await post(`${DOMAIN}/classService/api/class/getAllClassInfo`, {
    schoolId,
    gradeId,
    type,
    pageIndex,
    pageSize,
  })
}

export const apiGetGradeClass = async ({
  schoolId,
  gradeId,
  mode,
  pageIndex,
  pageSize,
}) => {
  return await post(`${DOMAIN}/classService/api/class/getGradeClass`, {
    schoolId,
    gradeId,
    mode,
    pageIndex,
    pageSize,
  })
}

export const apiGetAllClassMember = async ({ classId, role, userId }) => {
  return await post(`${DOMAIN}/classService/api/class/getAllClassMember`, {
    classId,
    role,
    userId,
  })
}

export const apiGetAllTeacherInfo = async ({ schoolId, classId }) => {
  return await post(`${DOMAIN}/classService/api/class/getAllTeacherInfo`, {
    schoolId,
    classId,
  })
}

export const apiGetClassInfoByUserId = async ({ userId }) => {
  return await post(`${DOMAIN}/classService/api/class/getClassInfoByUserId`, {
    userId,
  })
}

export const apiCreateStudent = async ({
  schoolId,
  gradeId,
  classId,
  name,
  gender,
  avatar,
  fPhone,
  mPhone,
  guardianPhone,
}) => {
  return await post(`${DOMAIN}/classService/api/class/createStudent`, {
    schoolId,
    gradeId,
    classId,
    name,
    gender,
    avatar,
    fPhone,
    mPhone,
    guardianPhone,
  })
}

export const apiCreateClass = async ({
  schoolId,
  gradeId,
  type,
  phaseType,
  name,
  clsNum,
  modeAdmin,
}) => {
  return await post(`${DOMAIN}/classService/api/class/createClass`, {
    schoolId,
    gradeId,
    type,
    phaseType,
    name,
    clsNum,
    modeAdmin,
  })
}

export const apiUpdateClass = async ({
  schoolId,
  classId,
  grade,
  name,
  clsNum,
  modeAdmin,
}) => {
  return await post(`${DOMAIN}/classService/api/class/updateClass`, {
    schoolId,
    classId,
    grade,
    name,
    clsNum,
    modeAdmin,
  })
}

export const apiDissolveClass = async ({ schoolId, classId }) => {
  return await post(`${DOMAIN}/classService/api/class/dissolveClass`, {
    schoolId,
    classId,
  })
}

export const apiJoinClass = async ({ classId, targetId, role }) => {
  return await post(`${DOMAIN}/classService/api/class/joinClass`, {
    classId,
    targetId,
    role,
  })
}

export const apiGetClassInfoById = async ({ classId }) => {
  return await post(`${DOMAIN}/classService/api/class/getClassInfoById`, {
    classId,
  })
}

export const apiQuitClass = async ({ schoolId, classId, targetId }) => {
  return await post(`${DOMAIN}/classService/api/class/quitClass`, {
    schoolId,
    classId,
    targetId,
  })
}

export const apiSetClassMaster = async ({ schoolId, classId, userId }) => {
  return await post(`${DOMAIN}/classService/api/class/setClassMaster`, {
    schoolId,
    classId,
    userId,
  })
}

export const apiTransferCharge = async ({
  schoolId,
  classId,
  userId,
  targetId,
}) => {
  return await post(`${DOMAIN}/classService/api/class/transferCharge`, {
    schoolId,
    classId,
    userId,
    targetId,
  })
}

export const apiGetAreaInfo = async () => {
  return await post(`${DOMAIN}/classService/api/common/getAreaInfo`)
}

export const apiGetNamesFromIds = async ({ type, ids, attach }) => {
  return await post(`${DOMAIN}/classService/api/common/getNamesFromIds`, {
    type,
    ids,
    attach,
  })
}

export const apiGetNationInfo = async () => {
  return await post(`${DOMAIN}/classService/api/common/getNationInfo`)
}

export const apiGetPartyInfo = async () => {
  return await post(`${DOMAIN}/classService/api/common/getPartyInfo`)
}

export const apiGetSchoolGradeList = async ({ schoolId }) => {
  return await post(`${DOMAIN}/classService/api/common/getSchoolGradeList`, {
    schoolId,
  })
}

export const apiUpdateSchoolGradeName = async ({
  schoolId,
  gradeId,
  gradeName,
}) => {
  return await post(`${DOMAIN}/classService/api/common/updateSchoolGradeName`, {
    schoolId,
    gradeId,
    gradeName,
  })
}

export const apiGetSchoolInfo = async ({
  province,
  city,
  county,
  phaseType,
  name,
  pageIndex,
  pageSize,
}) => {
  const res = await post(`${DOMAIN}/classService/api/common/getSchoolInfo`, {
    province,
    city,
    county,
    phaseType,
    name,
    pageIndex,
    pageSize,
  })

  if (res.result === 0) {
    res.data.forEach((v) => (v.mode = JSON.parse(v.mode)))
  }

  return res
}

export const apiGetSchoolSubject = async ({ schoolId }) => {
  return await post(`${DOMAIN}/classService/api/common/getSchoolSubject`, {
    schoolId,
  })
}

export const apiDelSchoolSubject = async ({ schoolId, subjectId }) => {
  return await post(`${DOMAIN}/classService/api/common/delSchoolSubject`, {
    schoolId,
    subjectId,
  })
}

export const apiAddSchoolSubject = async ({ schoolId, subjectId }) => {
  return await post(`${DOMAIN}/classService/api/common/addSchoolSubject`, {
    schoolId,
    subjectId,
  })
}

export const apiAddSchoolMember = async ({ schoolId, userId, exitSchool }) => {
  return await post(`${DOMAIN}/classService/api/school/AddSchoolMember`, {
    schoolId,
    userId,
    exitSchool,
  })
}

export const apiAddTeacher = async ({ schoolId, userInfo }) => {
  return await post(`${DOMAIN}/classService/api/school/addTeacher`, {
    schoolId,
    userInfo,
  })
}

export const apiJoinTeacher = async ({ schoolId, userId, classInfo }) => {
  return await post(`${DOMAIN}/classService/api/school/joinTeacher`, {
    schoolId,
    userId,
    classInfo,
  })
}

export const apiAddTeacherClassSubjectInfo = async ({
  userId,
  classId,
  subjectId,
}) => {
  return await post(
    `${DOMAIN}/classService/api/school/addTeacherClassSubjectInfo`,
    {
      userId,
      classId,
      subjectId,
    },
  )
}

export const apiDeleteTeacherClassSubjectInfo = async ({
  userId,
  classId,
  subjectId,
}) => {
  return await post(
    `${DOMAIN}/classService/api/school/deleteTeacherClassSubjectInfo`,
    {
      userId,
      classId,
      subjectId,
    },
  )
}

export const apiGetSchoolById = async ({ schoolId }) => {
  const res = await post(`${DOMAIN}/classService/api/school/getSchoolById`, {
    schoolId,
  })

  if (res.result === 0 && res?.data?.mode) {
    res.data.mode = JSON.parse(res.data.mode)
  }
  return res
}

export const apiRegistSchoolInfo = async ({
  areaId,
  phaseType,
  pid,
  mode,
  schoolName,
  comment,
  sameName,
  modeAdmin,
  property,
  idCode,
  contactName,
  contactPhone,
}) => {
  return await post(`${DOMAIN}/classService/api/school/registSchoolInfo`, {
    areaId,
    phaseType,
    pid,
    mode,
    schoolName,
    comment,
    sameName,
    modeAdmin,
    property,
    idCode,
    contactName,
    contactPhone,
  })
}

export const apiUpdateSchoolInfo = async ({
  schoolId,
  areaId,
  phaseType,
  pid,
  mode,
  schoolName,
  comment,
  sameName,
  modeAdmin,
  property,
  idCode,
  contactName,
  contactPhone,
}) => {
  return await post(`${DOMAIN}/classService/api/school/updateSchoolInfo`, {
    schoolId,
    areaId,
    phaseType,
    pid,
    mode,
    schoolName,
    comment,
    sameName,
    modeAdmin,
    property,
    idCode,
    contactName,
    contactPhone,
  })
}

export const apiGetSubjectTemplete = async ({ schoolId }) => {
  return await post(
    `${DOMAIN}/classService/api/common/getSchoolSubjectTemplete`,
    { schoolId },
  )
}

export const apiAddSchoolSubjectTemplete = async ({
  schoolId,
  relateId,
  name,
  shortName,
  desc,
}) => {
  return await post(
    `${DOMAIN}/classService/api/common/addSchoolSubjectTemplete`,
    { schoolId, relateId, name, shortName, desc },
  )
}

export const apiUpdateSchoolSubjectTemplete = async ({
  schoolId,
  id,
  relateId,
  name,
  shortName,
  desc,
}) => {
  return await post(
    `${DOMAIN}/classService/api/common/updateSchoolSubjectTemplete`,
    { schoolId, id, relateId, name, shortName, desc },
  )
}

export const apiGetSchoolTeachers = async ({
  schoolId,
  pageIndex,
  pageSize,
}) => {
  return await post(`${DOMAIN}/classService/api/school/getSchoolTeachers`, {
    schoolId,
    pageIndex,
    pageSize,
  })
}

export const apiSearchTeachersInSchool = async ({
  schoolId,
  gradeId,
  classId,
  name,
}) => {
  return await post(
    `${DOMAIN}/classService/api/school/searchTeachersInSchool`,
    {
      schoolId,
      gradeId,
      classId,
      name,
    },
  )
}

export const apiSearchStudentsInSchool = async ({
  schoolId,
  gradeId,
  classId,
  name,
}) => {
  return await post(
    `${DOMAIN}/classService/api/school/searchStudentsInSchool`,
    {
      schoolId,
      gradeId,
      classId,
      name,
    },
  )
}

export const apiShiftOutSchool = async ({ schoolId, userIds }) => {
  return await post(`${DOMAIN}/classService/api/school/shiftOutSchool`, {
    schoolId,
    userIds,
  })
}

export const apiAddUserByPhone = async ({ phone, name, gender }) => {
  return await post(`${DOMAIN}/classService/api/user/addUserByPhone`, {
    phone,
    name,
    gender,
  })
}

export const apiUpdateUserInfo = async ({
  userId,
  role,
  name,
  phone,
  avatar,
  gender,
  idCard,
  email,
  birth,
  faceImg,
  bloodType,
  nationality,
  native,
  politics,
  partyTime,
  coparation,
  education,
  interests,
  introduce,
  certCode,
  teachDate,
  classMasterDate,
  nation,
  relation,
}) => {
  return await post(`${DOMAIN}/classService/api/user/updateUserInfo`, {
    userId,
    role,
    name,
    phone,
    avatar,
    gender,
    idCard,
    email,
    birth,
    faceImg,
    bloodType,
    nationality,
    native,
    politics,
    partyTime,
    coparation,
    education,
    interests,
    introduce,
    certCode,
    teachDate,
    classMasterDate,
    nation,
    relation,
  })
}

export const apiGetUserBindInfoById = async ({ userId }) => {
  return await post(`${DOMAIN}/classService/api/user/getUserBindInfoByID`, {
    userId,
  })
}

export const apiUpdateUserBindInfo = async ({
  userId,
  cardType,
  cardNo,
  cardExt,
  faceRes,
  face,
}) => {
  return await post(`${DOMAIN}/classService/api/user/updateUserBindInfo`, {
    userId,
    cardType,
    cardNo,
    cardExt,
    faceRes,
    face,
  })
}

export const apiGetUserInfoById = async ({ userId }) => {
  const baseInfo = await post(`${DOMAIN}/classService/api/user/getBaseInfo`, {
    userId,
  })

  if (baseInfo.result !== 0) {
    return baseInfo
  }

  let extendInfo
  if (_.isEmpty(baseInfo.data)) {
    extendInfo = {}
  } else {
    extendInfo = await post(`${DOMAIN}/classService/api/user/getExtendInfo`, {
      userId,
    })

    if (extendInfo.result !== 0) {
      return extendInfo
    }
  }

  return {
    result: 0,
    msg: 'success',
    data: {
      ...baseInfo.data,
      ...extendInfo.data,
    },
  }
}

export const apiGetUserInfoByPhone = async ({ phoneNumber }) => {
  const baseInfo = await post(
    `${DOMAIN}/classService/api/user/getBaseInfoByPhonenumber`,
    {
      phoneNumber,
    },
  )

  if (baseInfo.result !== 0) {
    return baseInfo
  }

  let extendInfo
  if (_.isEmpty(baseInfo.data)) {
    extendInfo = {}
  } else {
    extendInfo = await post(`${DOMAIN}/classService/api/user/getExtendInfo`, {
      userId: baseInfo.data.userId,
    })

    if (extendInfo.result !== 0) {
      return extendInfo
    }
  }

  return {
    result: 0,
    msg: 'success',
    data: {
      ...baseInfo.data,
      ...extendInfo.data,
    },
  }
}

export const apiQueryBanpaiHardwareBindInfo = async({uuid}) => {
  return await post(`${DOMAIN}/app/banpai/device/queryBanpaiHardwareBindInfo`,{uuid})
}

export const apiQueryDeviceDetailInfo = async ({uuid}) =>{
  return await post(`${DOMAIN}/app/banpai/device/queryDeviceDetailInfo`,{uuid})
}

export const apiExecDeviceOnlineCommand = async ({uuid,command,params}) =>{
  return await post(`https://ws.banpai.app.aohuacloud.com/bponline/status/execDeviceOnlineCommand`,{uuid,command,params})
}

export const apiQueryBanpaiHardwareListInRange = async (
    {provinceId,cityId,countyId,schoolId,gradeId,classId,productId,pageIndex,pageCount}
  ) =>{
  return await post(`${DOMAIN}/app/banpai/device/queryBanpaiHardwareListInRange`,
  {provinceId,cityId,countyId,schoolId,gradeId,classId,productId,pageIndex,pageCount})
}

export const apiQueryDeviceShutdownInfo = async({uuid}) => {
  return await post(`${DOMAIN}/app/banpai/device/queryDeviceShutdownInfo`,{uuid})
}

export const apiQueryProgramShowList = async({uuid}) => {
  return await post(`${DOMAIN}/app/banpai/device/queryProgramShowList`,{uuid})
}

export const apiAddProviderInfo = async({id,name,desc,address,contact})=>{
  return await post(`${DOMAIN}/app/banpai/product/addProviderInfo`,{id,name,desc,address,contact})
}

export const apiQueryAllProviderInfoList = async({pageIndex,pageCount})=>{
  return await post(`${DOMAIN}/app/banpai/product/queryAllProviderInfoList`,{pageIndex,pageCount})
}

export const apiRemoveProviderInfo = async({id})=>{
  return await post(`${DOMAIN}/app/banpai/product/removeProviderInfo`,{id})
}

export const apiQueryAllProgramShowInfoList = async({pageIndex,pageCount}) =>{
  return await post(`${DOMAIN}/app/banpai/show/queryAllProgramShowInfoList`,{pageIndex,pageCount})
}

export const apiQuerySchoolDeviceShutdownInfo = async({pageIndex,pageCount}) => {
  return await post(`${DOMAIN}/app/banpai/shutdown/querySchoolDeviceShutdownInfo`,{pageIndex,pageCount})
}

export const apiQueryAllDeviceShutdownInfo = async({pageIndex,pageCount}) => {
  return await post(`${DOMAIN}/app/banpai/shutdown/queryAllDeviceShutdownInfo`,{pageIndex,pageCount})
}

export const apiQueryAllProgramInfoList = async({pageIndex,pageCount}) => {
  return await post(`${DOMAIN}/app/banpai/program/queryAllProgramInfoList`,{pageIndex,pageCount})
}

export const apiAddProgramInfo = async({id,name,desc,type,bundleId,scheme,url,param,installType,installUrl,versionName,versionCode}) =>{
  return await post(`${DOMAIN}/app/banpai/program/addProgramInfo`,{id,name,desc,type,bundleId,scheme,url,param,installType,installUrl,versionName,versionCode})
}

export const apiRemoveProgramInfo = async({id}) =>{
  return await post(`${DOMAIN}/app/banpai/program/removeProgramInfo`,{id})
}

export const apiQueryAllProductInfoList = async({pageIndex,pageCount}) =>{
  return await post(`${DOMAIN}/app/banpai/product/queryAllProductInfoList`,{pageIndex,pageCount})
}

export const apiAddProductInfo = async({id,name,desc,type,providerId}) =>{
  return await post(`${DOMAIN}/app/banpai/product/addProductInfo`,{id,name,desc,type,providerId})
}

export const apiRemoveProductInfo = async({id}) =>{
  return await post(`${DOMAIN}/app/banpai/product/removeProductInfo`,{id})
}

export const apiQueryAllProductTypeList = async({pageIndex,pageCount})=>{
  return await post(`${DOMAIN}/app/banpai/product/queryAllProductTypeList`,{pageIndex,pageCount})
}

export const apiRemoveProductType = async({id})=>{
  return await post(`${DOMAIN}/app/banpai/product/removeProductType`,{id})
}

export const apiAddProductType = async({id,typeName}) => {
  return await post(`${DOMAIN}/app/banpai/product/addProductType`,{id,typeName})
}
 
export const apiQueryAllProgramPublishInfoList = async({pageIndex,pageCount})=>{
  return await post(`${DOMAIN}/app/banpai/publish/queryAllProgramPublishInfoList`,{pageIndex,pageCount})
}

export const apiAddProgramPublishInfo = async({id,programId,provinceId,cityId,countyId,schoolId,type})=>{
  return await post(`${DOMAIN}/app/banpai/publish/addProgramPublishInfo`,{id,programId,provinceId,cityId,countyId,schoolId,type})
}

export const apiRemoveProgramPublishInfom = async({id})=>{
  return await post(`${DOMAIN}/app/banpai/publish/removeProgramPublishInfo`,{id})
}


export const apiAddProgramShowInfo = async({id,programId,provinceId,cityId,countyId,schoolId,gradeId,classId,productType,startTimeNumber,endTimeNumber,isCycle,dayOfWeek,cycleStartTime,cycleEndTime,priority})=>{
  return await post(`${DOMAIN}/app/banpai/show/addProgramShowInfo`,{id,programId,provinceId,cityId,countyId,schoolId,gradeId,classId,productType,startTime:startTimeNumber,endTime:endTimeNumber,isCycle,dayOfWeek,cycleStartTime:cycleStartTime,cycleEndTime:cycleEndTime,priority})
}